export const en = {
  general: {
    remark: 'Remark',
    message: 'Prognosis Remark',
    status: 'Status',
    actions: 'Actions',
    search: 'Search',
    quotations: 'Quotations',
    add: 'Add',
    print: 'Print',
    batch_add: 'Batch Add',
    add_prognosis: 'Prognosis',
    view: 'View',
    edit: 'Edit',
    save: 'Save',
    cancel_1: 'Cancel',
    approve: 'Approve',
    delete: 'Delete',
    remove: 'Remove',
    deleteall: 'Batch Deletion',
    duplicate: 'Duplicate',
    logout: 'Logout',
    confirm: 'Confirm',
    cancel: 'Cancel',
    discard: 'Discard',
    yes: 'YES',
    ok: 'Ok',
    no: 'NO',
    prompt: 'PROMPT',
    warning: 'WARNING',
    profile: 'Profile',
    passwd: 'Change Password',
    quantity: 'Quantity',
    permission: 'You do not have permission to do this operation',
    done: 'complete',
    add_field: 'Add',
    download: 'Download',
    export_data: 'Export Data',
    upload: 'Upload',
    export: 'Download File',
    model: 'Model',
    time: 'Time',
    copy: 'Copy',
    advanced_filter: 'Advanced Filter',
    reset: 'Reset',
    aps: 'APS',
    content: 'Content',
    keyword: 'Keyword',
    note: 'Note',
    editCompany: 'Edit Company Information',
    editContacts: 'Edit Contact Information',
    total: 'Total',
    lang: 'Language',
    down: 'Download successful',
    resetpassword: 'Reset Password',
    reset: 'Reset',
    domestic: 'Domestic',
    foreign: 'Foreign',
    del: 'Successfully delete',
    cancel_del: 'Cancel Delete',
    completed: 'Completed',
    completed_time: 'Completed Time',
    please_select: 'Please select...',
    add_new_label_dialog: 'Add New Label',
    lading: 'Loading...',
    delete_prompt: 'This will permanently delete this item, should I continue?',
    deleted_success: 'Deleted successfully',
    deleted_canceled: 'Deletion canceled',
    feedback_read: 'Provided',
    feedback_unread: 'No feedback',
    basic_info: 'Basic Information',
    details_info: 'Details Information',
  },
  name: 'Product Model',
  PN: 'PN',
  intro: 'Description',
  category: 'Category',
  family: 'Family',
  classfication: 'Classfication',
  description: 'Description',
  item_type: 'Type',
  region: 'Region',
  label_name: 'Label',
  status: 'PN Status',
  US_description: 'US Description',
  US_name: 'US Product Name',
  dwg: 'Drawing',
  range: 'Range',
  unit: 'Unit',
  Accuracy: 'Accuracy',
  measure_name: 'Measure Name',
  LOD: 'Limit Of Detection',
  '4-20mA output': '4-20mA Output',
  'Wiring Diagram': 'Wiring Diagram',
  Modbus: 'Modbus',
  'storage temperature': 'Storage Temperature',
  'Operating Temperature': 'Operating Temperature',
  'Minimum Flow': 'Minimum Flow',
  'Maximum Flow': 'Maximum Flo',
  'Maximum Pressure': 'Maximum Pressure',
  Size: 'Size',
  Weight: 'Weight',
  'Housing Material': 'Housing Material',
  'Electrode Material': 'Electrode Material',
  'Field Installation': 'Field Installation',
  'Connector Type': 'Connector Type',
  'Wiring Definition': 'Wiring Definition',
  'IP Rating': 'IP Rating',
  'Hardware Version': 'Hardware Version',
  'Software Version': 'Software Version',
  'Latest Software': 'Latest Software',
  'Select the file': 'Upload File',
  'Upload the certification': 'Upload The Certification',
  'Upload pictures': 'Upload Pictures',
  'Add the attachment': 'Add The Attachment',
  'check file': 'Check File',
  file_: 'File',
  'Measure principle': 'Principle',
  'Light Source': 'Light Source',
  Repeatability: 'Repeatability',
  'Sample Interval': 'Sample Interval',
  'Response Speed': 'Response Speed',
  'Interface Size': 'Interface Size',
  'Environment Humidity': 'Environment Humidity',
  Altitude: 'Altitude',
  'Sample Water Temperature': 'Sample Water Temperature',
  'Minimum Flow Rate': 'Minimum Flow Rate',
  'Working Pressure': 'Working Pressure',
  '2D/3D File': '2D/3D File',
  'Thread Standard': 'Thread Standard',
  'Installation Methods': 'Installation Methods',
  'Warranty Time': 'Warranty Time',
  'Maintenance Intervals': 'Maintenance Intervals',
  'Calibration Period': 'Calibration Period',
  'Cleaning Kit': 'Cleaning Kit',
  'Maintenance Mode': 'Maintenance Mode',
  'Cable Length': 'Cable Length',
  'Working Temperature': 'Working Temperature',
  'BOM Version': 'BOM Version',
  accessories: 'Accessories',
  accuracy: 'Measurement Accuracy',
  analog: 'Analog Output',
  certificates: 'Certificates',
  detection: 'Limit of Detection',
  flow: 'Flow Limit',
  group: 'Group',
  hardware: 'Hardware Version',
  install: 'Installation Format',
  ip_rating: 'IP Rating',
  last_software: 'Latest Software',
  material: 'Housing Material',
  modbus: 'Modbus Output',
  output_pin: 'Output Pin Format',
  power: 'Power Request',
  pressure: 'Pressure Limit',
  rang: 'Measurement  Range',
  resolution: 'Measurement  Resolution',
  software: 'Software Version',
  temperature: 'Temperature Limit',
  mechnical_draw: 'Mechnical Drawings',
  image: 'Product Image',
  fluorescence: 'Fluorescence',
  conductivity: 'Conductivity',
  turbidity: 'Turbidity',
  do: 'DO',
  disinfection: 'Disinfection',
  environment: 'Environment',
  output: 'Output',
  basic_information: 'Basic Information',
  measure_index: 'Measure',
  input_output: 'Input Output',
  environmental_requirement: 'Environmental Requirement',
  job_requirement: 'Job Requirement',
  size_weight: 'Size & Weight',
  materials_equipment: 'Materials Equipment',
  install_information: 'Installation',
  after_sales_maintenance: 'Service Aggrement',
  software_hardware_version: 'Hardware&Software Version',
  product_certification: 'Product Certificates',
  product_image: 'Product Image',
  product_accessories: 'Product Accessories',
  product_information: 'Product Documents',
  choose_template: 'Choose Template',
  model: {
    name: 'Model',
    PN: 'PN',
    intro: 'Description',
    retail_price: 'Retail Price',
    t1_price: 'T1 Price',
    t2_price: 'T2 Price',
    t3_price: 'T3 Price',
    t4_price: 'T4 Price',
    currency: 'Currency',
    num: 'Count',
    quantity: 'Quantity',
    total_price: 'Total Price',
    cny: 'CNY',
    usd: 'USD',
    accessories: 'Accessories',
    accuracy: 'Measurement Accuracy',
    analog: 'Analog Output',
    certificates: 'Certificates',
    detection: 'Limit of Detection',
    flow: 'Flow Limit',
    group: 'Group',
    hardware: 'Hardware Version',
    install: 'Installation Format',
    ip_rating: 'IP Rating',
    last_software: 'Latest Software',
    material: 'Housing Material',
    modbus: 'Modbus Output',
    output_pin: 'Output Pin Format',
    power: 'Power Request',
    pressure: 'Pressure Limit',
    rang: 'Measurement  Range',
    resolution: 'Measurement  Resolution',
    software: 'Software Version',
    temperature: 'Temperature Limit',
    mechnical_draw: 'Mechnical Drawings',
    image: 'Product Image',
    fluorescence: 'Fluorescence',
    conductivity: 'Conductivity',
    turbidity: 'Turbidity',
    do: 'DO',
    disinfection: 'Disinfection',
    environment: 'Environment',
    cooling_water: 'Cooling Water',
    boiler: 'Boiler',
    drinking_water: 'Drinking Water',
    municipal_wastewater: 'Municipal Wastewater',
    industrial_wastewater: 'Industrial Wastewater',
    food_beverage: 'Food & Beverage',
    pharmaceutical: 'Pharmaceutical',
    add_accessories: 'Add Accessories',
    accessories_info: 'Accessories Information',
    make: 'Make',
    buy: 'Buy',
    oem: 'OEM',
    pending: 'Pending',
    us_name: 'US Product Name',
    us_intro: 'US Description',
    log: 'Record',
    no_match: 'No match product model',
    inactive: 'Rejected',
    active: 'Approved',
    draft: 'Applying',
    cabledialogtitle: 'Please select the cable type',
    new_label_tip: 'Please enter the label name',
    new_label_remark_tip: 'Please enter the label remark',
    approval_pending: "Pending Approvals",
    approval: "Approval",
    brand: 'Brand',
    brand_name: 'Brand Name',
    brand_intro: 'Brand Intro',
    new_brand_title: "Add Brand",
    edit_brand_title: "Edit Brand",
    add_brand_success: "Add Brand Success",
    add_brand_error: "Add Brand Error",
    edit_brand_error: "Edit Brand Error",
    edit_brand_success: "Edit Brand Success",
    service: 'Service'
  },
  product: {
    SN: 'Serial Number',
    tester: 'tester',
    date_produced: 'Production Date',
    status: 'Status',
    add_product: 'Add Product',
  },
  RMA: {
    serviceReport: 'Service Report',
    number: 'RMA Number',
    time: 'Return Time',
    customer: 'Customer',
    user: 'Tester',
    is_expired: 'Out of warranty',
    type: 'Types of services',
    feedback: 'Feedback',
    conclusion: 'Conclusion',
    mode: 'Process Mode',
    status: 'Status',
    time_complated: 'Finish Time',
    progress: 'Service Schedule',
    // prog: 'Schedule',
    step: 'Schedule',

    // new company
    Accountname: 'Account Name',
    priceprogram: 'Price Program',
    terms: 'Terms',
    accountid: 'Account ID',
    fax: 'Fax',
    date: 'Setup Date',
    contactperson: 'Main Contact First Name',
    lastperson: 'Main Contact Last Name',
    contactnumber: 'Main Phone',
    contactemail: 'Main Email',
    accountidassociated: 'Account ID Associated',
    billtoemail: 'Bill To Email',
    billone: 'Bill to 1',
    billtwo: 'Bill to 2',
    billthree: 'Bill to 3',
    billfour: 'Bill to 4',
    billfive: 'Bill to 5',
    shipone: 'Ship to 1',
    shiptwo: 'Ship to 2',
    shipthree: 'Ship to 3',
    shifour: 'Ship to 4',
    shifive: 'Ship to 5',
    buyersname: `Buyer's Name`,
    upsaccount: 'UPS Account',
    address: 'Customer Address',
    postal: 'Customer Postal',
    note: 'Notes',
    status: 'Status',

    rma: 'RMA#',
    applicationDate: 'Application Date',
    caseStatus: 'Case Status',
    personnelTaking: 'Pyxis Personnel Taking Request',
    nameNeeding: 'Name of Individual Needing Service',
    companyNeeding: 'Company of Individual Needing Service',
    typeofProduct: 'Type of Product',
    productSerialNumber: 'Product Serial Number',
    reportedNeed: 'Reported Service Need',
    resolution: 'Resolution',
    failureMode: 'Failure Mode',
    diagnosis: 'Diagnosis',
    SNProducts: 'SN of Replaced Products',
    nameofApplicant: 'Name of Applicant',
    companyofApplicant: 'Company of Applicant',
    returnShippingAddress: 'Return Shipping Address',
    emailofIndividualNeeding: 'Email of Individual Needing Service',
    phoneNumberofIndividualNeeding:
      'Phone Number of Individual Needing Service',
    phoneNumberofIndividualNeedingOld:
      'Phone Number of Individual Needing Service - Old',
    previousRMAAssociated: 'Previous RMA Associated',
    PrintAsAList: 'Print as a list',
    PrintAsSummary: 'Print as summary',
    printPage: 'Page',
    resend: 'Resend email',
    copy: 'Email Content',
    warn: 'The email content is empty',
  },
  // new
  aRMA: {
    upimage: 'Upload',
    newservicerequest: 'New Service Request',
    introduction:
      'Please make this form is filled correctly as a copy of the information will be emailed to the customer when you hit the `Submit` button.',
    nameofapplicant: 'Name of Applicant',
    companyofapplicant: 'Company of Applicant',
    emailofapplicant: 'Email of Applicant',
    phoneofapplication: 'Phone Number of Application',
    phoneofapplicationold: 'Phone Number of Application Old',
    applicationdate: 'Application Date',
    returnshippingaddress: 'Return Shipping Address',
    endUsertheSameasApplicant: 'Is End User the Same as Applicant?',
    nameofIndividualNeedingService: 'Name of Individual Needing Service',
    companyofIndividualNeedingService: 'Company of Individual Needing Service',
    emailofIndividualNeedingService: 'Email of Individual Needing Service',
    phoneNumberofIndividualNeedingService:
      'Phone Number of Individual Needing Service',
    phoneNumberofIndividualNeedingServiceOld:
      'Phone Number of Individual Needing Service-Old',
    pyxisPersonnelTakingRequest: 'Pyxis Personnel Taking Request',
    oldPyxisPersonnelTakingRequest:
      'Old Version - Pyxis Personnel Taking Request',
    previousRMAassociates: 'Previous RMA Associated',
    typeofproduct: 'Type of Product',
    productSerialNumber: 'Product Serial Number',
    reportedServiceNeed: 'Reported Service Need',
    picturesofproblem: 'Pictures of problem',
    RMA: 'RMA#',
    returnTracking: 'Return Tracking#',
    dateDefectiveMeterReceived: 'Date Defective Meter Received',
    failureMode: 'Failure Mode',
    diagnosis: 'Diagnosis',
    replacementProductShipped: 'Replacement Product Shipped',
    SNofReplacedProducts: 'SN of Replaced Products',
    resolution: 'Resolution',
    pyxisPersonnelOfferingResolution: 'Pyxis Personnel Offering Resolution',
    serviceCost: 'Service Cost',
    casestatus: 'Case Status',
    confirmprinting: 'Confirm printing?',
    export_pdf: 'Export PDF',
  },
  failureMode_status: {
    batteriesCorroded: 'Batteries Corroded',
    Buttonfailure: 'Button Failure',
    crackedcasing: 'Cracked Casing',
    customerservice: 'Customer Service',
    electronicfailureout: 'Electronic Failure(Out of Warranty)',
    electronicfailurereplaceunit: 'Electronic Failure(Replace Unit)',
    hardwareerror: 'Hardware Error',
    manufacturingdefect: 'Manufacturing Defect',
    nofaultfoundorcleaning: 'No Fault Found/Cleaning',
    notenoughinformation: 'Not Enough Information',
    phbattery: 'pH Battery',
    phmodulefailure: 'pH Module Failure',
    samplecellleak: 'Sample Cell Leak',
    SDcarderror: 'SD Card Error',
    softwarefailure: 'Software Failure',
    unstablereading: 'Unstable Reading',
    waterDamage: 'Water Damage',
    other: 'Other',
  },
  resolution_status: {
    repairedandreturned: 'Repaired and returned',
    newreplacement: 'New replacement',
    usedreplacement: 'Used replacement',
    noproblemandreturned: 'No problem and returned',
    outofwarranty: 'Out of warranty',
    other: 'other',
  },
  case_status: {
    new: 'New',
    revieworRepairInProgress: 'Review/Repair In Progress',
    TESTINGWaitingForPayment: 'TESTING: Waiting For Payment',
    closed: 'Closed',
    received: 'Received',
  },
  // new contacts
  contacts: {
    name: 'Customer Name',
    company: 'Account',
    enduseraccount: 'End User Account',
    role: 'Role',
    businessname: 'Business Name',
    addressone: 'Address Line 1',
    addresstwo: 'Address Line 2',
    addressthree: 'Address Line 3',
    city: 'City',
    state: 'State',
    zipcode: 'Zip code',
    country: 'Country',
    receivingaddress: 'Receiving Address',
    email: 'email',
    directphone: 'Direct Phone',
    secondphone: 'Second Phone',
    upsaccount: 'UPS Account',
    specialnotes: 'Special Notes',
    addtime: 'Addtime',
  },
  role_status: {
    user: 'User',
    buyer: 'Buyer',
    tech: 'Tech',
    distributor: 'Distributor',
    other: 'Other',
  },
  customer_status: {
    active: 'Active',
    inactive: 'Inactive',
    reactive: 'Reactive',
    nameChanged: 'Name Changed',
    other: 'Other',
  },
  price: {
    listprice: 'List Price',
    awt: 'AWT',
    smallDistribution: 'Small Distribution',
    bulkDistribution: 'Bulk Distribution',
    asiaPacific: 'Asia Pacific',
    other: 'Other',
  },
  terms: {
    Net30: 'Net 30',
    Net60: 'Net 60',
    advance: '100% Advance',
    dueOnReceipt: 'Due on receipt',
    creditCard: 'Credit Card',
    paymentInAdvance: 'Payment in Advance',
    other: 'Other',
  },

  RMA_status: {
    all: 'All',
    done: 'Done',
    undone: 'In process',
    cancel: 'Cancel',
  },
  regions: {
    all: 'All',
    us: 'US',
    cn: 'CN',
    eu: 'EU',
    other: 'OTHER',
  },
  RMA_type: {
    return: 'Return',
    back: '外借退回',
  },
  RMALog: {
    status: 'Status',
    time: 'Time',
    user: 'User',
    userInfo: 'User',
  },
  WL: {
    number: 'Express Number',
    express: 'Express Company',
    from: 'From',
    to: 'To',
    addr: 'Address',
    time: 'Time',
    shipper: 'Sender`s name',
    consignee: 'Receive`s name',
    address: 'Address',
    received_date: 'Time',
  },
  Dep: {
    name: 'Name',
    intro: 'Introduction',
    date_joined: 'Added Time',
    last_update: 'Updated Time',
  },
  user: {
    address: 'Address',
    date_joined: 'Added Time',
    email: 'Email',
    is_active: 'Activate',
    is_admin: 'System Administrator',
    name: 'Name',
    phone: 'Phone',
    type: 'Type',
    username: 'Username',
    password: 'Password',
    nomal: 'Regular User',
    dep_admin: 'Department Administrator',
    admin: 'Admin',
    manager: 'Manager',
  },
  other: {
    to: 'To',
    start: 'Start',
    end: 'End',
    search: 'Please Enter Keyword',
    sn_search: 'Please Enter SN Keyword',
    progress: 'Update RMA progress',
    order_pro: 'The Delivery Number',
    error: 'Operation failed, please try again',
    success: 'Operation successful',
    confirm: 'Are you sure you want to delete this product',
    select_datetime: 'Select DateTime',
    select_date: 'Select Date',
    select_time: 'Select Time',
    select: 'Select',
    two: 'Please enter five-digit PN and six-digit SN,separated by the symbol "/"',
    order_hint:
      '*The quantity shipped this time has nothing to do with the quantity already shipped',
    prompt_info:
      'Whether to save the current page data and go to the next/previous page',
    save_info: 'Whether to save the current page data',
    input: 'Please enter content',
    quick_answer: 'Quick Answer',
    approve_confirm: 'PN Created Successfully',
    required: 'This field is required',
    email: 'Whether to send an Email to the address of applicant?',
    reset: "Are you sure you want to reset the user's password to the default?",
    enter_num: 'please enter a number',
    select_region: 'Select Sales Region',
    select_shipping: 'Select Shipping Status',
    del_order:
      'This action will permanently delete the order, do you want to continue?',
    del_confirm: 'Delete or not',
    action_warn: 'Whether to continue the operation?',
  },
  prog: {
    receive: 'Accepted',
    totest: 'Handed over to test',
    fault: 'Fault analysis',
    mode: 'Process mode',
    conclusion: 'Result judgment',
  },
  basicInfo: {
    RMA: 'RMA Basic Information',
    Log: 'Log Information',
    WL: 'Logistics Information',
    User: 'User Information',
    Group: 'Department Information',
    Product: 'Product Information',
    Model: 'Product Model Information',
  },
  prognosis: {
    OK: 'OK',
    lack_materials: 'Lack Materials',
    technical_difficulties: 'Technical Difficulties',
    in_stock: 'Inventory to meet',
    in_stock1: 'Inventory Partially Satisfied',
    tight_delivey_date: 'Tight Delivery Date',
    prognosis_status: 'Prognosis Status',
    stock_allot: 'Stock Allot',
    choose: 'Pleaase Choose',
  },
  bug: {
    time: 'Time',
    user: 'Tester',
    version: 'Version',
    task: 'Task',
    result: 'Result',
    issue: 'Description',
    attachment: 'Attachment',
  },
  CRP: {
    time: 'Vacancy report time',
    name: 'Materials',
    name_col: 'Materials Column',
    code: 'Material Code',
    type: 'Material Type',
    code_col: 'Material Code Column',
    adduser: '报缺人',
    existing: 'Existing Inventory',
    existing_col: 'Existing Inventory Column',
    demand: 'Vigilance Inventory',
    quantity: 'Quantity Required',
    quantity_col: 'Quantity Required Column',
    mrp_file: 'MRP File',
    p_time: 'Purchasing Time',
    p_user: 'Purchasing Agent',
    p_status: 'Status',
    p_quantity: 'Purchase Quantity',
    EDT: 'Expected arrival time ',
    ADT: 'Actual arrival time',
    ADUser: 'Confirmation of arrival',
    status_1: 'Procured in transit',
    status_2: 'Arrived',
    status_0: 'Not Purchase',
    p_expected: 'Scheduling',
    p_expected_col: 'Scheduling Column',
    reason: 'Reason',
    reason_d: '采购数量与需求数量相差的原因',
    priority: 'Priority',
    priority_col: 'Priority Column',
    stock: 'stock',
    production: 'Production',
    production_col: 'Production Column',
    urgent: 'Urgent',
    rs_item: 'Vacancy Report Item',
    rs_item_col: 'Vacancy Report Item Column',
    mes_1: 'Vacancy Report Information',
    mes_2: 'Demand Information',
    mes_3: 'Purchasing Information',
    mes_4: 'Arrival Information',
    mes_5: 'Goods Information',
    det: 'Product Detail',
    file: 'Product File',
    meter: 'Meters',
    equ: 'Equipment',
    che: 'Chemical',
    rd: 'R&D',
    other: 'Other',
    remark: 'Product Remark',
    link: 'Purchase Link',
    ADUser: 'Signatory',
    app_note: 'Approval Comment',
    select_app: 'Approval Status',
    to_approver: 'Select Approver',
    vacancy_info: 'Vacancy information ',
    demand_info: 'Demand information',
    purchasing_info: 'Purchasing Information',
    arrival_info: ' Arrival information',
    aog: 'AOG',
    IBD: 'IBD',
    warehouse: 'Warehouse keeper',
    storage: 'Storage quantity',
    storage_time: 'Storage time',
    aogquantity: 'AOG quantity',
    partial_arr: 'Partial arrival'
  },
  wl: {
    express: 'Express Company',
    trackingnumber: 'Tracking number',
    shipper: 'Sender',
    time: 'Shipper Time',
    addr: 'Shipper Address',
    consignee: 'Recipients',
    address: 'Delivery address',
    received_date: 'Delivery Time',
    status_0: 'Shipped',
    status_1: 'Been Received',
    status_2: 'Deleted',
    add_recipients_tip: 'Please check the recipients under blow.',
  },
  passwd: {
    old: 'Original Password',
    new: 'New Password',
    confirm: 'Confirm Password',
    1: 'Password changed successfully',
    2: 'The original password is wrong. Please re-enter it',
    3: 'Sorry, the new password and confirming password disagree!',
    4: 'The new password cannot be the same as the old one',
    5: 'Password is not less than 6 characters',
  },
  order: {
    num: 'Order number',
    user: '下单人',
    time: 'Add Time',
    del_date: 'Delivery Date',
    expected_date: ' Expected Delivery Date',
    client: 'Customer',
    consignee: 'Consignee',
    contact: 'Contact',
    address: 'Address',
    email: 'Email',
    status: 'Status',
    time_completed: 'Complete/cancel time',
    progress: 'Progress',
    item: 'Goods List',
    quantity: 'Quantity',
    product: 'Product Quantity',
    producted: 'Arranged Production ',
    send: 'To deliver',
    wl: 'Logistics',
    receive: 'Receiving',
    send_q: 'Quantity',
    send_q_ed: 'To send',
    not_send: 'Still left to be delivered',
    order: 'Order',
    aggregate: 'Aggregate',
    generate_plan: 'Generate Plan',
    all_stock: 'All Stock',
    order_cancel: 'Order Canceled',
    product_cancel: 'Product Canceled',
    contract: 'Contract',
    view_contract: 'View Contract',
  },
  plan: {
    PO: 'Production order',
    addtime: 'Added time',
    adduser: 'Added User',
    addby: 'Added By',
    creator: 'Creator',
    created_by: 'Created By',
    level: 'Level',
    soft: 'SoftVersion',
    hard: 'HardVersion',
    complete: 'Quantity Completion',
    done: 'Completed Progress',
    email: 'Email Notify',
    device: 'Device Group',
    instrument: 'Instrument Group',
    select_model: 'Select Model',
    other: 'Other',
    select: 'Select',
    arrange_production: 'Arrange production',
    batch_quantity: 'Batch quantity',
    operation_date: 'Operation Date',
    set_sn: 'SET SN',
    start_sn: 'Staring SN',
    batch_class: 'Batch Class',
    batch_num: 'Batch Num',
    batch: 'Batch',
    in_batches: 'In batches',
    whole_batch: 'Whole batch',
    stock_redistrib: 'Stock Redistribution',
    batch_count: 'Batch arranged',
    overdue: 'Overdue Reminder',
    overdue1: 'Overdue',
    overdue_soon: 'Overdue Soon',
    overdue_status: 'Overdue Status',
    exception_add: 'Exception',
    schedule_date: 'Schedule Date',
    allocate: 'Allocate inventory',
  },
  product_process: {
    date_entry: 'Required date of entry',
    inventory: 'Inventory progress',
    latest_entry: 'Latest Entry',
    exception: 'Exception reporting',
    picking_quantity: 'Picking Quantity',
    in_assembly: 'In assembly',
    once_test: 'In one test',
    under_repair: 'Under Repair',
    reading_back: 'Reading back',
    stocked: 'Stocked',
    complete_assembly: 'Complete assembly',
    complete_test: 'Complete a test',
    complete_repairs: 'Complete Repairs',
    complete_reading: 'Complete Readback',
    complete_stock: 'Complete Stocking',
    diary: 'Operation Diary',
    picking_edit: 'Picking Edit',
    picking: 'Picking',
    batch_edit: 'Batch Edit',
    scrapped: 'Scrapped',
    scrapped_time: 'Time',
    finish_time: 'Finish in time',
    overdue_complete: 'Overdue completion',
    save_warn: 'SN cannot be stored and scrapped at the same time',
    complete_scrap: 'Scrapped',
  },
  file: {
    name: 'File Name',
    file: 'File',
    time: 'Created Time',
    type: 'Type',
    prog: 'Schedule',
    hard: 'Hardware Change Report',
    soft: 'Software Change Report',
    choujian: 'Sampling Inspection Report',
    test: 'Test Report',
    process: 'Process Change Report',
    certificates: 'Certificates',
    mechnical_draw: 'Mechnical Draw',
    image: 'Product Image',
    finished_product: 'Finished Product Inspection Record',
    standard: 'IQC Standard File',
    public: 'Public Technical Documents',
  },
  order_prog: {
    0: 'Placed',
    1: 'Arrange Production',
    2: 'Stored',
    3: 'Shipped',
    4: 'Completed',
    5: 'Production to be Arrange',
    6: 'Product Complate',
  },
  okr: {
    goal: 'Personal Goals',
    result: 'Key results',
    first: 'First Quarter',
    second: 'Second Quarter',
    third: 'Third Quarter',
    fourth: 'Fourth Quarter',
  },
  model_category: {
    handheld_devices: 'HANDHELD DEVICES + ACCESSORIES',
    inline_sensors: 'INLINE SENSORS + ACCESSORIES',
    chemical_reagents: 'Chemical Reagents',
    display_controllers_cloud: 'DISPLAYS & CONTROLLERS & CLOUD DATA',
    code: 'Code',
    name: 'Class Name',
    new_dialog_title: "Add New Product Category",
    new_dialog_title_edit: "Edit Product Category",
    new_dialog_tip: "Please enter the new category information below.",
  },
  model_family: {
    handheld_devices: 'HANDHELD DEVICES',
    handheld_acc: 'HANDHELD ACCESSORIES',
    standard_inline_sensors: 'STANDARD INLINE SENSORS 7Pin (4-20mA & RS-485)',
    standard_inline_sensors_acc: 'STANDARD INLINE SENSORS ACCESSORIES',
    oxidizer_sensors: 'OXIDIZER SENSORS 8Pin=2x 4-20mA & RS-485',
    oxidizer_sensors_acc: 'OXIDIZER INLINE SENSORS ACCESSORIES & PANELS',
    dissolved_oyxgen_sensors:
      'DISSOLVED OYXGEN SENSORS & PANELS 8 Pin = 2x 4-20mA & RS-485',
    dissolved_oyxgen_acc: 'DISSOLVED OYXGEN ACCESSORIES',
    ultra_low_turbidity_sensors:
      'ULTRA-LOW TURBIDITY SENSORS 8 Pin = 2x 4-20mA & RS-485',
    ultra_low_turbidity_acc: 'ULTRA-LOW TURBIDITY ACCESSORIES',
    digital_inline_sensors: 'DIGITAL INLINE SENSORS 5Pin (RS-485 Only)',
    digital_inline_acc: 'DIGITAL INLINE SENSOR ACCESSORIES',
    corrosion_sensors: 'CORROSION SENSORS 8 Pin =2x 4-20mA & RS-485',
    corrosion_acc: 'CORROSION SENSOR  ACCESSORIES',
    level_sensors: 'LEVEL SENSORS',
    level_sensor_acc: 'LEVEL SENSOR ACCESSORIES',
    sugar_analyzer: 'SUGAR ANALYZER & SENSOR',
    sugar_analyzer_parts: 'SUGAR ANALYZER SPARE-REPLACEMENT PARTS',
    inline_chemistry_analyzers: 'INLINE CHEMISTRY ANALYZERS',
    inline_chemistry_analyzers_parts:
      'INLINE CHEMISTRY ANALYZERS - REAGENTS & SPARE PARTS',
    tracing_chemicals: 'TRACING CHEMICALS',
    calibration_standards: 'CALIBRATION STANDARDS',
    chemical_reagents: 'Chemical Reagents',
    display_controllers_cloud: 'DISPLAYS & CONTROLLERS & CLOUD DATA',
  },
  quote: {
    normal: 'Normal Quote',
    special: 'Special Quote',
    valid: 'Valid',
    invalid: 'Invalid',
    sales: 'Sales',
    market: 'Market',
    service: 'Customer Service',
    technology: 'Technology',
    save_next_step: 'Save to the next step',
    select_product: 'Select Product',
    qnum: 'Quote No.',
    lnum: 'Quote Line No.',
    date: 'Date',
    customer_name: 'Customer',
    contacts: 'Contacts',
    department: 'Department',
    operation: 'Operation',
    company: 'Company',
    theme: 'Theme',
    area: 'Area',
    status: 'Status',
    terms: 'Select Terms',
    text: 'Remark',
    title: 'Choose Product',
    china: 'China',
    usa: 'USA',
    europe: 'Europe',
    quotation_details: 'Quotation Details',
    file_format: 'File Format',
    show_price: 'Quotation Display Price',
    download_dialog_title: "Download File",
    download_dialog_tip: "Which kind of file you need to download?",
    customer_dialog_title: "Choose a customer",
    edit_product_dialog_title: "Edit Product",
    add_dialog_title: "Add Product",
    search_customer_placeholder: 'Please enter the customer name',
  },
  tech_info: {
    file_type: 'File Type',
    date: 'Date',
    created_date: 'Created Date',
    software: 'SoftWare',
    hardware: 'HardWare',
    structure: 'Structure',
    pack: 'Pack',
    label: 'Label',
    BOM: 'BOM',
    specification: 'Specification',
    tool: 'Tool',
    File_version: 'Version',
    audit_status: 'Audit Status',
    audit: 'To be Audited',
    completed_audit: 'Completed Audited',
    auditor: 'Auditor',
    relevant_file: 'Relevant file',
    tooltip: 'This view permission request is only valid for 7 days',
    reason: 'Reason for application',
    request_view: 'Request to view',
    tech_file_tip: 'This viewing privilege is only valid for 7 days after approval.',
  },
  purchase: {
    user: 'Create User',
    company: 'Company',
    contract_contents: 'Contract Contents',
    amount: 'Amount',
    head: 'Head',
    contract_NO: 'Contract NO.',
    remark: 'Remark',
    date: 'Date',
    folder_name: 'Folder Name',
    material_name: 'Material Name',
    spec: 'Spec',
    unit_measure: 'Unit Measure',
    apply_remark: 'Remark',
    apply_status: 'Apply Status',
    material_type: 'Material Type',
    material_number: 'Material NO.',
    approval_status: 'Approval Status',
    approval_remark: 'Approval Remark',
    old_number: 'Old Material NO.',
    agree: 'Agree',
    refuse: 'Refuse',
    no_approval: 'NO Approval',
    undone: 'In process',
    done: 'Done',
    cancel: 'Cancel',
    approval: 'Approval',
    error: 'The approval has been completed.',
    code_rule: 'Code Rule',
    proposer: 'Proposer',
    approver: 'Approver',
    origin: 'Origin',
    brand_model: 'Brand Model',
    picture: 'Picture',
    adduser: 'Apply User',
  },
  menus: {
    Dashboard: 'Dashboard',
    board: 'Board',
    print: 'Print',
    file: 'File',
    PLM: 'PLM',
    compare_price: 'Compare Price',
    SOP: 'SOP',
    product: 'Product',
    product_m: 'Product Model',
    model: 'Product Detail',
    price_management: 'Price Management',
    quote: 'Quote',
    normal: 'Normal Quote',
    special: 'Special Quote',
    okr: 'OKR',
    order: 'Order',
    mps: 'MPS',
    mrp: 'MRP',
    purchasing_requisition: 'Requests',
    quality: 'Quality File',
    inspection: 'Quality',
    iqc: 'IQC',
    process_defect: 'Process Defect',
    rma: 'RMA',
    contacts: 'Contacts',
    department: 'Department',
    user: 'Users',
    techinfo: 'Tech Info',
    techinfo2: 'Tech Info2',
    purchase: 'Purchase',
    amn: 'AMN',
    purchase_contract: 'Purchase Contract',
    admin: 'Admin',
    users: 'Users',
    userInfo: 'Userinfo',
    role: 'Roles',
    perm: 'Permissions',
    test: 'Instrument Testing',
    add_test: 'Add Test',
    test_detail: 'Test Detail',
    test_edit: 'Edit TestInfo',
    test_copy: 'Copy TestInfo',
    oqc_template: 'OQC Template',
    feedback: 'Feedback',
    record: 'Maintenance Records',
    maintenance: 'Maintenance',
    material: 'Materials',
    customer: 'Customer',
    operate: 'Operate',
    product_process: 'Production Process',
    model_category: 'Product Category',
    instruction: 'Manual',
    email: 'Email notification',
    contact: 'Contacts',
    approvalFlow: 'Approval Flow',
    supplier: 'Supplier',
    contract: 'Contract',
    craft: 'Craft',
    craft_feedback: 'Craft Feedback',
    SN_scrapped: 'Scrapped SN',
    documents: 'Documents',
    process_test_documents: 'Documents',
    log: 'Log',
    tech_info_file_log: 'Tech Info Log',
    material_apply_log: 'MN Log',
    instrument_test_file_download_log: 'Test Download Log',
    material_num: 'Material Number',
    apply_mn: 'Application MN',
    notification: 'Notification',
    material_classification: 'Classification',
    SOP_file: 'SOP File',
    pm: 'PM',
    unit: 'Unit',
    mn_brand: 'MN Brand',
    /*仓库管理*/
    warehouse_management: 'Warehouse',
    administrative_warehouse: 'Administrative',
  },
  purchasing_requisition: {
    purchase_requisition: 'Purchase Requisition',
    basic_information: 'Basic Information',
    document_number: 'Doc NO.',
    applicant: 'Applicant',
    application_department: 'Department',
    estimated_total_amount: 'Estimate Total',
    approver: 'Approver',
    notes: 'Notes',
    application_date: 'Date',
    purchaser: 'Purchaser',
    audit: 'Audit',
    RD_projects: 'Project',
    product: 'Product',
    detailed_list: 'Detailed List',
    add: 'Add',
    edit: 'Edit',
    delete: 'Delete',
    serial_number: 'Index',
    material_number: 'Material Number',
    material_name: 'Material',
    specification_and_model: 'Specification & Model',
    unit: 'Unit',
    number: 'Number',
    estimated_unit_price: 'Estimated Unit Price',
    estimated_total_price: 'Estimated Total Price',
    remark: 'Remark',
    related_accessories: 'Related Accessories',
    review_failed: 'Review Failed',
    audited: 'Audited',
    first_approval: 'Under first level approval',
    in_approval: 'In price comparison',
    second_approval: 'Under second level approval',
    pending_approval: 'Pending approval',

    purPriceComparison: 'Purchase Price Comparison Table',
    procurementDept: 'Procurement Department',
    purchasedept: 'Purchase department',
    purnumber: 'Number',
    supplier: 'Supplier',
    TaoBao: 'TaoBao',
    JD: 'JD',
    misumi: 'Misumi',
    productName: 'Product Name',
    purQuantity: 'Purchase quantity',
    planDemand: 'Delivery Time',
    deliverydate: 'Delivery Date',
    deliverymethod: 'Delivery method',
    freight: 'Freight',
    quote: 'Quote',
    priceintax: 'Total price including tax',
    bill: 'Bill(VAT invoice/Ordinary invoice/No invoice)',
    settlement: 'Settlement method',
    logistics: 'Logistics',
    expressdelivery: 'Express delivery',
    selfpickup: 'Self pickup',
    universalticket: 'Universal ticket',
    additionalvotes: 'Additional votes',
    noticket: 'No ticket',
    suggested_supplier: 'Suggested supplier',
    partial: 'Partial purchase',
    purchased: 'Purchased',
    buy_status_ms: 'Is it confirmed that it has been purchased?',
  },
  purchase_review: {
    RD_projects: 'R&D projects',
    estimated_total_amount: 'Estimated Total',
    actual_total_amount: 'Actual Total',
    remark: 'Remark',
    serial_number: 'Serial Number',
    material_number: 'Material Number',
    material_name: 'Material Name',
    specification_and_model: 'Specification And Model',
    unit: 'Unit',
    number: 'Number',
    estimated_unit_price: 'Estimated Unit Price',
    estimated_total_price: 'Estimated Total Price',
    actual_unit_price: 'Actual Unit Price',
    actual_total_price: 'Actual Total Price',
    review_failed_comments: 'Review Failed Comments',
    finding_of_audit: 'Finding of audit',
    goback: 'Return',
    process: ' Process',
    firstResults: 'First level approval results',
    purchaseResults: 'Purchase Results',
    supplier: 'SUPPLIER',
    unitprice: 'unit-price',
    amount: 'Amount of money',
    comparator: 'Comparator',
    approvaltime: 'Approval time',
    approver: 'Approver',
    approvalres: 'Approval results',
    appopinions: 'Approval opinions',
    approvalInfo: 'Approval Information',
    compareprices: 'Compare prices',
    Pricec_details: 'Price comparison details',
    Compare_or_not: 'Compare prices or not',
  },
  purchase_btn: {
    view: 'View',
    edit: 'Edit',
    del: 'Delete',
    export: 'Export',
    PM: 'PM'
  },
  approvalFlow: {
    name: 'name',
    type: 'type',
    content: 'content',
    Approver: 'Approver',
    product_approval: 'PN Approval',
    mrp_approval: 'Material shortage reporting approval',
    tech_audit: 'Technical data approval',
    amn_approval: 'Material No. Approval',
    approvalFlowInfo: 'Approval process information',
  },
  dashbord: {
    unpurchase_0: 'Not Purchase',
    intransit_0: 'Procured in transit',
  },
  techinfo: {
    user: 'User'
  },
  IQC: {
    time: 'Inspection Time',
    user: 'Inspection User',
    quantity: 'Inspection Quantity',
    ratio: 'Inpspection Ratio',
    batch: 'Batch Number',
    standard: 'Inspection Standard',
    result: 'Inspection Result',
    // res_0: "PASS",
    res_0: 'PASS',
    res_1: 'Enter Warehouse',
    pass: 'Fail',
    checkout: 'Checkout',
    status_0: 'Pending Inspected',
    status_1: 'Pending Stocked',
    // status_2: "已入库",
    status_2: 'Pending Return',
    status_3: 'Pending Scrap',
    status_4: 'Stocked',
    status_5: 'Checked',
    mes_6: 'IQC',
    batch_quality: 'batch Quantity',
    pass_quality: 'Pass Quantity',
    pass_ratio: 'Pass Ratio',
    mes: 'Incomplete Data Entry',
    result_1: 'Return',
    result_2: 'Scrapped',
    result_3: 'Concession Receipt',
    followup: 'Follow Up',
    result_4: 'Supplier Rework Processing',
    result_5: 'Pyxis Rework Processing',
    type_0: 'Active element',
    type_1: 'Photocell',
    type_2: 'Electrical components',
    type_3: 'Fluid components',
    type_4: 'Cable harness',
    type_5: 'Structural member',
    type_6: 'Packaging materials',
    type_7: 'Printing',
    type_8: 'Circuit board',
    type_9: 'glue',
    type_10: 'other',
    type_11: 'Screw & O-rings',
    standard_0: 'null',
    standard_4: 'QP-IQC-01-2020',
    standard_5: 'QP-IQC-11-2021',
    standard_6: 'QP-IQC-02-2022',
    standard_7: 'QP-IQC-03-2022',
    standard_11: 'QP-IQC-11-2022',
  },
  perm: {
    name: 'Name',
    code: 'Code',
    intro: 'Intro',
    open: 'Open',
    enable: 'Enabled',
    num: 'Num',
    id: 'ID',
    add_perm: 'Add Permission',
    edit_perm: 'Edit Permission',
    roles: 'Roles',
    assign_roles: 'Assign Roles',
    assign_perm: 'Assign Permission',
    role_management: 'Role Management',
    add_role: 'Add Role',
    edit_role: 'Edit Role',
    role_name: 'Role Name',
    role_intro: 'Role Intro',
    require_name_tip: 'The Permission name cannot be empty',
    require_code_tip: 'The permission code cannot be empty',
    info: 'Role Information',
    perm: 'Permission',
  },
  test: {
    add_template: 'Add Template',
    add_report_template: 'OQC Template',
    create_report: 'Create Report',
    template_type: 'Template Type',
    template_file: 'Template File',
    cell_PN: 'Cell for PN',
    cell_SN: 'Cell for SN',
    cell_instrument_type: 'Cell for Model',
    cell_report_code: 'Cell for Report Code',
    cell_test_date: 'Cell for Test Date',
    cell_software: 'Cell for Software',
    cell_hardware: 'Cell for Hardware',
    cell_mac: 'Cell for BT Mac',
    cell_modbus: 'Cell for Modbus',
    cell_tester: 'Cell for Tester',
    cell_auditor: 'Cell for Auditor',
    portable: 'Portable',
    portable2: 'Portable(TWO)',
    handheld: 'Handheld',
    handheld2: 'Handheld(TWO)',
    online: 'Online',
    in_line: 'In-line Optics',
    electrode_box: 'Electrode Box',
    handheld_instrument: 'Hand-held Instrument Colorimetric',
    handheld_colorimetric: 'Hand-held Instrument Colorimetric Reading',
    create_excel: 'Create Excel',
    instrument_type: 'Model',
    report_code: 'Export Code',
    test_date: 'Test Date',
    software: 'Software',
    hardware: 'Hardware',
    mac: 'BT Mac',
    tester: 'Tester',
    auditor: 'Auditor',
    parameter: 'Parameter File',
    qualified: 'Qualfied',
    unqualified: 'Unqualified',
    qualified_or_not: 'Qualified or not',
    retest: 'Retest',
    repairs: 'Repairs',
    end: 'End',
    concession: 'Concession shipping',
    device: 'Device',
    start: 'Start time',
    end: 'End time',
    item: 'item name',
    cn: 'CN',
    en: 'EN',
    template: 'No language template is available',
    adaptor: 'Adaptor',
  },
  process_defect: {
    id: 'ID',
    date: 'Date',
    material_code: 'Material Code',
    material_name: 'Material Name',
    bad_description: 'Bad Description(Word)',
    bad_description1: 'Bad Description(Picture)',
    test: 'Test',
    followup: 'Follow Up',
    required_return: 'Required Return',
    actual_return: 'Actual Return',
    classification: 'Question Categories',
    q_feedback1: 'Feedback',
    q_feedback2: 'Picture',
    feedback: 'Submitter',
    analysis: 'Quality Analyse',
    type_0: 'Technical question',
    type_1: 'Poor process',
    type_2: 'Poor incoming material',
    type_3: 'Process change',
    type_4: 'Other',
  },
  record: {
    adduser: 'Delivery User',
    feedback: 'Feedback',
    analysis: 'Failure Analysis',
    repair: 'Failure Repair',
    type: 'Failure Type',
    c_time: 'Repair Completion Time',
    type_0: 'Technical Issues',
    type_1: 'Bad Process',
    type_2: 'Bad Incoming Material',
    type_3: 'Bad detection',
    type_4: 'Other',
    edituser: 'Responsibility',
    result: 'Test',
    status_0: 'Pending repair',
    status_1: 'Pending Test',
    status_2: 'Completed',
    status_3: 'Unrepaired',
    test_remark: 'Testremark',
    file: 'File',
    after_sale: 'Return Material Authorization',
  },
  release: {
    pn: 'PN',
    model: 'Product Model',
    uploadFiles: 'Upload files',
    givedept: 'Distribution department',
    basicDoc: 'Basic documents',
    bom: 'BOM',
    sop_fit: 'SOP Fit',
    sop_test: 'SOP Test',
    packageFile: 'Package file',
    MecDrawing2D: 'Mechanical Drawing 2D',
    MecDrawing3D: 'Mechanical Drawing 3D',
    electric_base: 'Electric base file',
    electric_SCH: 'Electric SCH',
    electric_wire_map: 'Electric Wire Map',
    instructions: 'Manual',
    RDdata: 'R&D data',
    others: 'Other',
    selectDept: 'Please select a department',
    free: 'Release',
    freedet: 'Release Details',
    sendNotice: 'Send notifications',
    releaseTime: 'Released Time',
    changeTime: 'Changed Time',
    feedback_status: 'Feedback Status',
    version: 'Version',
    techinfo: 'Technical Changes',
    suggest: 'Suggest',
    operation: 'Operation Project',
    pro_change: 'Hardware Changes',
    prodet: 'Change Details',
    proadd: 'Change Details',
    change_detail: 'Change Detail',
    add_new_software_change: 'Add New Software Change',
    proedit: 'Change Details',
    proapprove: 'Pending Approve',
    review_tip: 'Please go to the code repository to review the code',
    waiting: 'To Be Edited',
    tech_audited: 'Technical Audited',
    tech_edit: 'Technical Edited',
    operate_edit: 'Operation Edited',
    stock: 'Quantity in stock',
    wip: 'WIP',
    uploadfile: 'File Uploaded',
    filedep: 'File department',
    no_feedback: 'No Feedback',
    not_edited: 'Not Edited',
    choose_status: 'Please select a status',
    add_pn_dialog_title: "Add PN",
    add_file_dialog_title: "Add File",
    edit_file_dialog_title: "Edit File",
    software_change_upgrade_both: "Upgrade both client and inventory",
    software_change_upgrade_client: "Upgrade client only",
    software_change_upgrade_inventory: "Upgrade inventory only",
    software_change_not_upgrade_both: "Not upgrade both client and inventory",
    please_select_a_level: "Please select a level",
    please_select_a_status: "Please select a status",
    changed_info: 'Changes',
    file_info: 'Files',
    search_pn_placeholder: 'Please type PN to search...',
    feedback_timeout: 'Feedback Timeout',
  },
  notice: {
    notification: 'Notifications',
    notify: 'Notify',
    ordinaryNotice: 'Ordinary Notice',
    releaseNotice: 'Release Notice',
    changeNotice: 'Change Notice',
    softchangeNotice: 'Software Change Notice',
    feedbacktz: 'Feedback Notice',
    feedbacktime: 'Feedback Time',
    feedback: 'Feedback',
    subfeedback: 'Submit Feedback',
    title: 'Title',
    ifRead: 'Has it been read',
    sendTime: 'Sending time',
    type: 'Type',
    details: 'Details',
    read: 'Read',
    unread: 'Unread',
    content: 'Content',
    chemical: 'Chemical Engineering',
    insite: 'In-site notification',
    newtime: 'New Time',
    receivedep: 'Receiving department',
    receiveuser: 'Recipient',
    insiteDet: 'In-site notification details',
    electronic: 'Electronic and electrical',
    structural: 'Structural packaging',
    engineeing: 'Process engineering ',
    documents: 'Documentation',
    feedback_type: 'Feedback Type',
  },
  // 工艺
  craft: {
    feedback: 'Feedback',
    c_feedback1: 'Craft Feedback(Word)',
    c_feedback2: 'Craft Feedback(Picture)',
    analysis: 'Craft Analyse',
    feedback_status: 'Feedback Status',
    feedback_detail: 'Feedbacks',
    assemble_sop: 'Assemble SOP',
    pack_sop: 'Pack SOP',
    test_sop: 'Test SOP',
    update_content: 'Update content',
  },
  //软件变更
  soft_change: {
    pro_soft_change: 'Software Changes',
    pro_soft_add: 'Software Change Add Details',
    change_software: 'Change software',
    change_level: 'Change level',
    download_link: 'Download link',
    change_content: 'Change content',
    please_select_pn: 'Please fill in the PN number',
    please_fill_change_software: 'Please fill in the change software',
    please_fill_download_link: 'Please fill in the download link',
    please_fill_change_level: 'Please fill in the change level',
    please_fill_change_content: 'Please fill in the changes',
    operation_failed: 'The operation failed!',
    please_fill_tech_info: 'Please fill in the technical change information'
  },
  // 物料号
  material: {
    category_type_subclass: 'Select Category type subclass',
    edit_classification: 'Edit Classification',
    view_classification: 'View Classification',
    add_classification: 'Add Classification',
    category_name_connot_none: 'The category name cannot be empty',
    category_code_connot_none: 'The category identifier cannot be empty',
    enter_two_code: 'Please enter a two-digit code',
    enter_key_filter: 'Enter a keyword to filter',
    code: 'Code',
    parent_level: 'Parent Level',
    batch_approval: 'Batch Approval'
  },
  cable_dialog: {
    "sensor_side_explosion": "Sensor side Explosion-proof",
    "length": "Length",
    "unit": "Unit",
    "the_other_side_PIN": "The other side PIN",
    "the_other_side_adaptor": "The other side adaptor",
    "the_other_side_explosion": "The other side Explosion-proof",
    "sensor_side_PIN": "Sensor side PIN",
    "sensor_side_adaptor": "Sensor side adaptor",
  },
  quality: {
    add_quanitiy_dialog_title: 'Add Quantity file',
  },
  pm: {
    name: 'Project Name',
    intro: 'Project Intro',
    status: 'Status',
    start: 'Start Date',
    end: 'End Date',
    remark: 'Remark',
    new_pm_title: "Add New Project",
    edit_pm_title: "Edit Project",
    add_pm_success: "Add Project Success",
    add_pm_error: "Add Project Error",
    edit_pm_error: "Edit Project Error",
    edit_pm_success: "Edit Project Success",
    active: 'Active',
    inactive: 'Incative',
    schematic: 'Schematic',
    upload: 'Click to upload',
    hint: 'jpg/png files with a size less than 5M'
  },
  number: {
    time: 'Date',
    code: 'Number',
    name: 'Name',
    spec: 'Specification',
    status: 'Status',
    remark: 'Remark',
    release: 'Release',
    change: 'Change',
  },
  unit: {
    name: 'Unit Name',
    intro: 'Unit Introduction',
    precision: 'Precision'
  },
  mn_brand: {
    name: 'Brand Name',
    number: 'Brand Number',
    remark: 'Remark'
  }
}

export default en
