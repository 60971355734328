import * as Vue from 'vue'
import Layout from '@/layout'
// import Vue from '@/main'
export default {
  path: '/log',
  component: Layout,
  name: 'log',
  alwaysShow: true,
  meta: {title: 'menus.log', icon: 'log'},
  children: [
    {
      path: 'techinfo',
      name: 'techinfo_file_view_log',
      component: () => import('@/views/logs/techinfo_file_log.vue'),
      meta: {title: 'menus.tech_info_file_log', icon: 'flow-logs'}
    },
    {
      path: 'material',
      name: 'material_apply_log',
      component: () => import('@/views/logs/material_apply_log.vue'),
      meta: {title: 'menus.material_apply_log', icon: 'logs'}
    },
    {
      path: 'instrument_test',
      name: 'instrument_test_file_download_log',
      component: () => import('@/views/logs/instrument_test_file_download_log.vue'),
      meta: {title: 'menus.instrument_test_file_download_log', icon: 'logs'}
    },
  ]
}
